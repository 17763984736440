import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { TYPOGRAPHY } from '../../constants';

const GlobalOverrideStyles = createGlobalStyle`
  :root {
    --pf-font-family-base: ${TYPOGRAPHY.fontFamily.base};
  }
`;

export const DesignSystemOverrides = () => {
  return <GlobalOverrideStyles />;
};
